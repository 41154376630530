// App.js
import './App.css';
import React, { useState, useEffect, useReducer } from 'react';
import { CssBaseline } from '@mui/material';

import tagsDocumentData from './tags_document.json';
import Alert from './Alert';
import Finish from './Finish';
import FooterButton from './FooterButton';
import Grid from './Grid';
import Header from './Header';
import MainContent from './MainContent';
import gameReducer from './GameReducer';
import getPuzzleNumber from './PuzzleNumber';
import TitleBar from './TitleBar';

// Identify which puzzle number we want
let puzzleNumber = getPuzzleNumber();
if (isNaN(puzzleNumber)) {
    console.error('Failed to calculate puzzle number');
    puzzleNumber = 0;
}

// Convert JSON data to Map
const entireDocument = new Map(Object.entries(tagsDocumentData));
if (!entireDocument.has(puzzleNumber.toString())) {
    console.error(`Puzzle number ${puzzleNumber} not found in document`);
    // set puzzle number to the first one present in the document
    puzzleNumber = Math.min(...Array.from(entireDocument.keys()).map(Number));
}
const tagsDocument = new Map(Object.entries(entireDocument.get(puzzleNumber.toString())));

// Assign colors to tags
const colorPool = ['#FF5733', '#026a15', '#3357FF', '#FF33A1'];
const cardTags = new Map();
// Iterate over tags document entries
Array.from(tagsDocument.entries()).forEach(([tag, cardIds]) => {
  for (const cardId of cardIds) {
    cardTags.set(cardId, tag);
  }
});

const tagColors = new Map();
Array.from(tagsDocument.keys()).forEach((tag, index) => {
  tagColors.set(tag, colorPool[index % colorPool.length]);
});

const initialGameState = {
  'number': getPuzzleNumber(),
  'selected': [],
  'cardTags': cardTags,
  'tags': tagsDocument,
  'groups': new Map(),
  'colors': tagColors,
  guesses: [],
  attemptsRemaining: 4,
  endDismissed: false,
}

function App() {

  const [alert, setAlert] = useState({ message: '', type: '' });
  const [items, setItems] = useState([]);

  const [game, dispatch] = useReducer(gameReducer, initialGameState)

  useEffect(() => {
    const handleAlert = (event) => {
      const {message, type} = event.detail
      setAlert({message, type})
      // Clear any existing timeout
      if (window.alertTimeout) {
        clearTimeout(window.alertTimeout);
      }

      // Set new timeout
      window.alertTimeout = setTimeout(() => setAlert({message: '', type: ''}), 4000);
    }
    window.addEventListener('show-alert', handleAlert)

    return () => window.removeEventListener('show-alert', handleAlert)
  }, []);

  useEffect(() => {
    // Fetch image URLs for each UUID
    const fetchData = async () => {
      const cardUUIDs = Array.from(tagsDocument.values()).flat();
      // Shuffle the array of UUIDs
      for (let i = cardUUIDs.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [cardUUIDs[i], cardUUIDs[j]] = [cardUUIDs[j], cardUUIDs[i]];
      }
      const fetchedItems = await Promise.all(
        cardUUIDs.map(async (uuid) => {
          try {
            const response = await fetch(
              `https://api.scryfall.com/cards/${uuid}`
            );
            const data = await response.json();

            // If multiple card faces, just show the front one?
            // TODO: support flipping the card if it's multifaced
            let front, back
            if (data.card_faces) {
              front = data.card_faces[0].image_uris ? data.card_faces[0].image_uris.png : null;
              back = data.card_faces[1].image_uris ? data.card_faces[1].image_uris.png : null;
            } else {
              front = null
              back = null
            }

            // Handle cases where image_uris might not be available
            const imageUrl = data.image_uris
              ? data.image_uris.png
              : 'placeholder_image_url';

            return {
              id: uuid,
              imageUrl: imageUrl,
              selected: false,
              grouped: false,
              groupId: null,
              groupColor: null,
              front: front,
              back: back,
            };
          } catch (error) {
            console.error('Error fetching data:', error);
            return {
              id: uuid,
              imageUrl: 'placeholder_image_url',
              selected: false,
              grouped: false,
              groupId: null,
              groupColor: null,
            };
          }
        })
      );
      setItems(fetchedItems);
    };

    fetchData();
  }, [tagsDocument]);

  const onShuffle = () => {
    if (game.attemptsRemaining === 0) {
      return;
    }
    setItems(items => {
      const shuffled = [...items];
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }
      return shuffled;
    });
  }

  return (
    <>
      <CssBaseline />
      <div style={{
        backgroundColor: '#000000',
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
        alignItems: 'start',
      }}>

      {/* Header Bar */}
      <TitleBar />

      <MainContent>
        <div className="finish-container">
          <Finish game={game} />
        </div>
        <div className="header-container">
          <Header game={game} onShuffle={onShuffle}/>
        </div>
        <div
          className="alert-container"
          style={{
            zIndex: 9999,
          }}>
          <Alert message={alert.message} type={alert.type} />
        </div>
        <div className="grid-container">
          <Grid
            items={ items }
            setItems={ setItems }
            game={ game }
            dispatch={ dispatch }
          />
        </div>
      </MainContent>

      {/* Footer Button */}
      <FooterButton game={game} dispatch={dispatch} />
      </div>
    </>
  )
}

export default App;
