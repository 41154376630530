import React, { useState } from 'react';
import { Card, CardHeader, CardMedia, Box, Button, IconButton, Paper, Stack } from '@mui/material';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Refresh from '@mui/icons-material/Refresh';


const GridCard = ({ id, item, game, dispatch, isLocked }) => {

    const gameOver = game.attemptsRemaining === 0;
    isLocked = gameOver || isLocked;

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({
        id: id,
        disabled: isLocked,
        data: {
            isLocked: isLocked,
        }
    });

    const [showBack, setShowBack] = useState(false);

    let group = null;
    if (game.attemptsRemaining !== 0) {
        let found = false;
        for (const [tag, groupData] of game.groups.entries()) {
            if (groupData.ids.includes(item.id)) {
                group = {
                    tag: tag,
                    color: groupData.color
                };
                found = true;
                break;
            }
        }
    } else {
        const tag = game.cardTags.get(item.id);
        group = {
            tag: tag,
            color: game.colors.get(tag)
        }
    }
    const isSelected = game.selected.includes(item.id);

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: isLocked ? undefined : transition,
    };

    const cardStyle = {
        cursor: 'default',
        border: item.selected ? '2px solid white' : 'none',
        backgroundColor: item.groupColor || 'transparent',
    };

    let hasBack = false;
    let imageUrl;
    if (item.front != null && item.back != null) {
        // by default show front, include a button to flip
        hasBack = true;
        if (showBack) {
            imageUrl = item.back;
        } else {
            imageUrl = item.front;
        }
    } else {
        imageUrl = item.imageUrl;
    }
    return (
        <div
            ref={setNodeRef}
            {...attributes}
            {...listeners}
            style={{
                ...style,
                padding: '5px',
                textAlign: 'center',
                background: group ? group.color : isSelected ? '#788028': game.selected.length == 4 ? 'black' : 'white',
                boxShadow: isSelected ? '0 2px 4px rgba(255,255,255,0.1)' : 'none',
                borderRadius: '8px',
                transition: 'background-color 0.25s ease',
                cursor: isLocked ? 'default': 'pointer',
            }}
        >
            <Stack spacing={0} position="relative">
                {hasBack && (
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowBack(!showBack);
                        }}
                        sx={{
                            position: 'absolute',
                            top: {xs: 0, sm: 4},
                            left: {xs: 0, sm: 4},
                            zIndex: 1,
                            width: { xs: 16, sm: 24, md: 32},
                            height: { xs: 16, sm: 24, md: 32},
                            backgroundColor: 'rgba(255,255,255,0.8)',
                            '&:hover': {
                                backgroundColor: 'rgba(255,255,255,0.9)'
                            }
                        }}
                    >
                        <Refresh fontSize="small" />
                    </IconButton>
                )}
            </Stack>
            <Card
                sx={cardStyle}
                onClick={() => {
                    if (isSelected) {
                        dispatch({ type: 'unselect_card', cardId: item.id })
                    } else {
                        dispatch({ type: 'select_card', cardId: item.id })
                    }
                }}
                raised={isSelected}
            >
                <Button
                    disableRipple
                    sx={{ padding: 0 }}
                >
                    <CardMedia
                        component="img"
                        image={imageUrl}
                        alt={ item.name }
                        sx={{ height: '100%', width: '100%', objectFit: 'contain' }}
                    />
                </Button>
            </Card>
        </div>
  );
};

export default GridCard;
