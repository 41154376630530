// something to compute which puzzle it is
// we assume we release one puzzle a day

const firstReleaseDate = new Date('2024-12-07');

function getPuzzleNumber() {
    const today = new Date();
    const diffTime = Math.abs(today.getTime() - firstReleaseDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    return diffDays;
}

export default getPuzzleNumber;
