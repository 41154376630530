function Alert({message, type}) {

    const alertStyles = {
        padding: "1rem",
        border: type === "error" ? "1px solid red" : "1px solid green",
        backgroundColor: type === "error" ? "#f8d7da" : "#d4edda",
        color: type === "error" ? "#721c24" : "#155724",
        borderRadius: "5px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        transition: "opacity 0.5s ease-in-out",
        opacity: message ? 1 : 0,
        userSelect: 'none',
      };
    
      return message ? <div style={alertStyles}>{message}</div> : null;
}

export const showAlert = (message, type) => {
    window.dispatchEvent(new CustomEvent('show-alert', {detail: {message, type}}))
}

export default Alert;
