// shiggles only


const quotes = [
    {
        text: "I see you've mastered the art of being wrong. Impressive, truly",
        author: "Jace Beleren"
    },
    {
        text: "Was that supposed to be clever?",
        author: "Jace Beleren"
    },
    {
        text: "Maybe the goblins can give you a few pointers",
        author: "Jace Beleren"
    },
    {
        text: "Did you even read the cards?",
        author: "Jace Beleren"
    },
    {
        text: "Congratulations, you've made me lose faith in both logic and magic",
        author: "Jace Beleren"
    },
    {
        text: "Inscrutable, truly",
        author: "Jace Beleren"
    },
    {
        text: "Your incompetence is matched only by your persistence",
        author: "Liliana Vess"
    },
    {
        text: "Did you really think that would work?",
        author: "Liliana Vess"
    },
    {
        text: "Sweetheart, even a shambling corpse wouldn't guess that",
        author: "Liliana Vess"
    },
    {
        text: "Adorable. Do it again so I can laugh harder",
        author: "Liliana Vess"
    },
    {
        text: "I've seen worms with better judgment",
        author: "Liliana Vess"
    },
    {
        text: "I've seen candles with more briliance",
        author: "Chandra Nalaar" 
    },
    {
        text: "Your strategy has more holes than a Phyrexian's conscience",
        author: "Teferi"
    },
    {
        text: "No amount of time reversal can save you from that guess",
        author: "Teferi"
    },
    {
        text: "Did you plan that in advance? Surely not",
        author: "Teferi"
    },
    {
        text: "Maybe take a few extra turns to think about that",
        author: "Teferi"
    },
    {
        text: "That's a fascinating approach. Wrong, but fascinating",
        author: "Nissa Revane"
    },
    {
        text: "You've blundered so badly the trees are whispering about it",
        author: "Nissa Revane"
    },
    {
        text: "About two steps away from being fertilizer",
        author: "Nissa Revane"
    },
    {
        text: "I've seen moss with better instincts",
        author: "Nissa Revane"
    }
]

function getRandomQuote() {
    return quotes[Math.floor(Math.random() * quotes.length)];
}

export { getRandomQuote };