import React from 'react';
import { Box, IconButton } from '@mui/material';
import Refresh from '@mui/icons-material/Refresh';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import GuessIndicator from './GuessIndicator';

const Header = ({ game, onShuffle }) => {
  const onScrollToBottom = () => {
    window.scrollBy({
        top: window.innerHeight,
        behavior: 'smooth',
    })
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding={1}
      sx={{
        backgroundColor: '#000000',
      }}
    >
      {/* Shuffle Button */}
      <IconButton onClick={onShuffle} sx={{ color: 'white' }}>
        <Refresh />
      </IconButton>

      {/* Guess Indicators */}
      <Box display="flex" gap={1}>
        {game.guesses.map((guess, index) => (
          <GuessIndicator key={index} guess={guess} colors={game.colors}/>
        ))}

        {/* Empty Guess Indicators */}
        {Array.from({ length: game.attemptsRemaining }).map((_, index) => (
          <Box
            key={`empty-${index}`}
            width={24}
            height={24}
            borderRadius="50%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: 'transparent',
              border: '1px dashed #ccc',
            }}
          />
        ))}

        {/* TODO: if game is over, show all missing groups */}
      </Box>

      {/* Scroll to Bottom Button */}
      <IconButton onClick={onScrollToBottom} sx={{ color: 'white' }}>
        <ArrowDownwardIcon />
      </IconButton>
    </Box>
  );
};

export default Header;
