// finish screen
import { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Button, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

import { showAlert } from './Alert';

const emojiColorMap = new Map();
emojiColorMap.set('#FF5733', '🟧');
emojiColorMap.set('#026a15', '🟩');
emojiColorMap.set('#3357FF', '🟦');
emojiColorMap.set('#FF33A1', '🟪');

const OpenFinish = ({ open, onClose, game }) => {
    const getColor = (cardId) => {
        if (!game.cardTags.has(cardId)) {
            return 'transparent';
        }
        return game.colors.get(game.cardTags.get(cardId));
    }

    return (

    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{
        userSelect: 'none',
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2, backgroundColor: '#788028', color: 'white' }}>
        
        <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
          {game.groups.size === 4 ? (
            game.guesses.length === 4 ? "🤯 Perfect!" : "🎉 Success!"
          ) : "😢 Try Again Tomorrow!"}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers sx={{ backgroundColor: '#000000'}}>
        <Typography
            variant="h6"
            align="center"
            color="white"
            sx={{ fontWeight: 'bold' }}
            gutterBottom
        >
            Groups
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          {Array.from(game.colors.entries()).map(([tag, color]) => (
            <div
              key={tag}
              style={{
                backgroundColor: color,
                color: 'white',
                padding: '12px',
                borderRadius: '8px',
                textAlign: 'center',
                fontWeight: 'bold'
              }}
            >
              {tag}
            </div>
          ))}
        </div>
        </DialogContent>

        <DialogContent dividers sx={{ backgroundColor: '#000000'}}>
        <Typography
            variant="h6"
            align="center"
            color="white"
            sx={{ fontWeight: 'bold' }}
            gutterBottom
        >
            Guesses
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', alignItems: 'center' }}>
          {game.guesses.map((guess, index) => (
            <div key={index} style={{ display: 'flex', gap: '4px' }}>
              {Array.from({ length: guess.ids.length }).map((_, elementIndex) => (
                <div
                  key={elementIndex}
                  style={{
                    width: '24px',
                    height: '24px',
                    borderRadius: '2px',
                    backgroundColor: getColor(guess.ids[elementIndex])
                  }}
                />
              ))}
            </div>
          ))}
        </div>
      </DialogContent>

      <DialogActions sx={{ backgroundColor: '#000000'}}>
        <Button 
          variant="contained" 
          onClick={() => {
            // generate output string first
            let output = `MTG Connections - ${game.number}\nhttps://mtgc.alberthyang.com\n\n`;

            for (const guess of game.guesses) {
                for (const id of guess.ids) {
                    output += emojiColorMap.get(game.colors.get(game.cardTags.get(id))) ?? '❓';
                }
                output += '\n';
            }

            navigator.clipboard.writeText(output);

            showAlert('Results Copied to Clipboard');
          }}
          sx={{
            userSelect: 'none',
            backgroundColor: '#788028',
          }}
        >
          Share
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Finish = ({ game }) => {
    const [dismissed, setDismissed] = useState(false);

    return (
        <OpenFinish
            open={game.attemptsRemaining === 0 && !dismissed}
            onClose={() => {
                setDismissed(true)
            }}
            game={game}
        />
    )
}

export default Finish;
