import { showAlert } from './Alert';
import { getRandomQuote } from './Mockery';

// define struct for guess
const Guess = {
    ids: [],
    correct: false,
    tag: null,  // set to tag id if correct
}

function evaluateGroup(state, selected) {
    for (const [tag, cardIds] of state.tags.entries()) {
        if (selected.every(id => cardIds.includes(id))) {
            return tag;
        }
    }
    return null;
}

function isGuessClose(state, selected) {
    for (const [tag, cardIds] of state.tags.entries()) {
        let count = 0;
        for (const id of selected) {
            if (cardIds.includes(id)) {
                count += 1;
            }
        }
        if (count === 3) {
            return true;
        }
    }
    return false;
}

function gameReducer(state, action) {
    switch (action.type) {
        case 'submitGuess':
            if (state.selected.length !== 4) {
                showAlert(`Select 4 cards first`, 'error')
                return state;
            }

            // adding one more will get us to the correct size, so
            // do an evaluation right now
            const validTag = evaluateGroup(state, state.selected);
            if (validTag !== null) {
                const newGroups = new Map(state.groups);
                newGroups.set(
                    validTag,
                    {
                        'ids': [...state.selected],
                        'color': state.colors.get(validTag),
                    }
                )
                showAlert(`Found group ${validTag}!`, 'success')
                return {
                    ...state,
                    selected: [],
                    guesses: [...state.guesses, {
                        ids: [...state.selected],
                        correct: true,
                        tag: validTag,
                    }],
                    groups: newGroups,
                    attemptsRemaining: newGroups.size === 4 ? 0 : state.attemptsRemaining,
                };
            } else if (isGuessClose(state, state.selected)) {
                showAlert(`Off By One!`, 'error')
            } else {
                const quote = getRandomQuote()
                const out = `${quote.text} - ${quote.author}`
                showAlert(out, 'error')
            }

            return {
                ...state,
                selected: [],
                guesses: [...state.guesses, {
                    ids: [...state.selected],
                    correct: false,
                    tag: null,
                }],
                attemptsRemaining: state.attemptsRemaining - 1,
            }
        case 'select_card':
            // if the game is over, do nothing
            if (state.attemptsRemaining === 0) {
                return state;
            }

            // action should contain card id
            // if card is already selected, do nothing
            if (state.selected.includes(action.cardId)) {
                return state;
            }

            // if we are trying to select more than four cards, do nothing
            if (state.selected.length >= 4) {
                return state;
            }

            return {...state, selected: [...state.selected, action.cardId]};
        case 'unselect_card':
            // if the game is over, do nothing
            if (state.attemptsRemaining === 0) {
                return state;
            }

            // if card is not selected, do nothing
            if (!state.selected.includes(action.cardId)) {
                return state;
            }
            return {...state, selected: state.selected.filter(id => id !== action.cardId)};
        default:
            showAlert(`Something went wrong`, 'error')
            return state;
    }
}

export default gameReducer;
