import { Container } from '@mui/material';
import { useIsMobile } from './Device';

const MainContent = ({ children }) => {
  if (useIsMobile()) {
    return <Container
      maxWidth="sm" sx={{ mt: 3, mb: 3 }}
    >
      {children}
  </Container>
  } else {
    return <Container maxWidth="md">
      {children}
    </Container>
  }
};

export default MainContent;
