import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import { Help } from '@mui/icons-material';


function TitleBar() {
    return (
        <AppBar position="static" sx={{ alignItems: 'center', backgroundColor: '#788028' }}>
        <Toolbar sx={{ width: 380 }}>
          <img
            src="/wubrg-192.png"
            alt="Logo"
            style={{ height: '40px', marginRight: '16px' }}
          />
          <Typography
            variant="h6" 
            component="div"
            sx={{ 
              fontWeight: 'bold',
              textAlign: 'center',
              width: '100%'
            }}
          >
            MTG Connections
          </Typography>
          <IconButton sx={{ color: 'white'}} onClick={() => {
            
          }}>
            <Help />
          </IconButton>
        </Toolbar>
      </AppBar>
    )
}

export default TitleBar;