import { Box, Button } from '@mui/material';


function FooterButton({ game, dispatch }) {
    const fourSelected = game.selected.length == 4;
    return (
        <Box
            component="footer"
            sx={{
                position: 'fixed',
                bottom: 0,
                width: '100%',
                backgroundColor: fourSelected ? '#788028' : 'rgb(115, 115, 115)',
                color: 'white',
                padding: 2,
                boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.2)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000,
            }}
        >

            <Button
                variant="contained"
                onClick={() => dispatch({ type: 'submitGuess' })}
                disabled={!fourSelected}
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    height: '56px',
                    width: '100%',
                    boxShadow: 'none',
                    padding: 0,
                    margin: 0,
                    backgroundColor: fourSelected ? '#788028' : 'rgb(115, 115, 115)',
                    '&:hover': {
                        backgroundColor: fourSelected ? '#464a18' : 'rgb(135, 135, 135)'
                    },
                    '&:disabled': {
                        backgroundColor: 'rgb(115, 115, 115)',
                        color: 'rgb(255, 255, 255)',
                    }
                }}
            >
                {game.attemptsRemaining === 0 ? '' : game.selected.length != 4 ? 
                    `Select ${4 - game.selected.length} more ${game.selected.length == 3 ? 'card' : 'cards'}` : 
                    'Submit Guess'
                }
            </Button>
        </Box>
    )
}

export default FooterButton;
