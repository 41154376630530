import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

const GuessIndicator = ({ guess, colors }) => {
  const [expanded, setExpanded] = useState(false);

  const getColor = (tag) => {
    if (colors.has(tag)) {
      return colors.get(tag);
    }
    return 'transparent';
  }

  const toggleExpand = () => {
    if (!guess.correct) {
      return;
    }
    setExpanded(!expanded);
  };

  return (
    <Box
      onClick={toggleExpand}
      width={expanded ? 'fit-content' : 24} // Expand size on click
      height={24}
      borderRadius={expanded ? 8 : "50%"}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: guess.correct ? getColor(guess.tag) : 'transparent',
        border: '1px solid #ccc',
        position: 'relative',
        cursor: 'pointer',
        padding: expanded ? '0 12px' : 0,
        transition: 'all 0.3s ease', // Smooth animation
        overflow: 'hidden', // Prevent content overflow when collapsed
        whiteSpace: 'nowrap', // Prevent text wrapping
        userSelect: 'none',
      }}
    >
      {!guess.correct && !expanded && (
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            color: 'red',
            fontSize: '1rem',
            fontWeight: 'bold',
            lineHeight: 1,
            userSelect: 'none',
          }}
        >
          <Close />
        </Box>
      )}
      {expanded && (
        <Typography
          sx={{
            color: guess.correct ? 'white' : 'red',
            fontSize: '0.8rem',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {guess.tag}
        </Typography>
      )}
    </Box>
  );
};

export default GuessIndicator;
